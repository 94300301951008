<template>
  <div>
    <!-- Inputs -->
    <v-row
      class="px-4 pt-3"
    >
      <!-- Last Updated -->
      <v-col v-if="subTitle" cols="5" class="ml-2">
        <v-icon small>mdi-clock</v-icon>
        <span class="pa-2 text-h4">{{subTitle}}</span>
      </v-col>

      <v-spacer />

      <!-- Chart options -->
      <v-col
        cols="3"
        class="py-0 my-0"
      >
        <v-select
          v-if="reportType === 'Full Overview'"
          v-model="chartType"
          outlined
          label="Chart Type"
          :items="['Area', 'Line']"
        />
      </v-col>
      <v-col
        cols="3"
        class="py-0 my-0"
      >
        <v-select
          v-model="reportType"
          @change="generateReport(); chartType = 'Area'"
          outlined
          label="Report Type"
          :items="['Full Overview','Breakdown Report']"
        />
      </v-col>
      <v-col
        v-if="!campaignId"
        :cols="6"
        class="py-0 my-0"
      >
        <v-autocomplete
          v-model="selectedCampaign"
          outlined
          clearable
          label="Campaign"
          :items="campaigns"
          item-text="name"
          return-object
          @change="getCampaignBurstsAndScreens"
        />
      </v-col>
    </v-row>

    <v-row 
      v-if="generatingReport" 
      class="d-flex justify-center mt-6"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="100"
        :width="7"
      ></v-progress-circular>
    </v-row>

    <template v-if="!generatingReport">
      <!-- Chart -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-if="chartTableData"
            :headers="headers"
            :items="chartTableData"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <apexchart 
            v-if="chartLineData"
            :type="chartType.toLowerCase()" 
            height="500" 
            :options="sineChartOptions" 
            :series="sineChartOptions.series"
          >
          </apexchart>
        </v-col>
        <v-col cols="6"
          v-for="(chartLineDataSingle, index) in chartLineDataSingles" 
          :key="index"
        >
          <apexchart 
            v-if="chartLineDataSingle"
            type="line" 
            height="500" 
            :options="chartLineDataSingle" 
            :series="chartLineDataSingle.series">
          </apexchart>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
  import CampaignController from '@/services/controllers/Campaign'
  import ReportingController from '@/services/controllers/Reporting'
  import moment from 'moment'

  export default {
    props: ['campaignId'],

    data: () => ({
      chartType: 'Area',
      reportOpened: false,
      generatingReport: false,
      campaigns: [],
      availableBursts: [],
      availableScreens: [],
      selectedCampaign: null,
      selectedBurst: null,
      selectedScreen: null,
      reportType: 'Full Overview',
      reportLoading: false,
      chartTableData: null,
      chartLineData: null,
      chartLineDataSingles: [],
      sineChartOptions: {},
      subTitle: null,
      headers: [],
    }),

    filters: {
      prettyDateTime: function (date) {
        return moment(date).format('DD MMM YYYY HH:mm')
      },
    },

    async mounted() {
      await this.getAllCampaigns()
      await this.attachPropToCampaign()
      this.generateReport()
    },

    methods: {
      getCampaignBurstsAndScreens() {
        if (this.selectedCampaign.id !== 0) {
          this.availableBursts = this.selectedCampaign.campaignBursts
          this.availableBursts.push({ id: 0, name: 'All' })
          this.availableScreens = this.selectedCampaign.uniqueScreens
          this.availableScreens.push({ id: 0, name: 'All' })
        }
      },

      // Get Campaigns.
      async getAllCampaigns() {
        const res = await CampaignController.getAllCampaigns()

        this.campaigns = res.data
        this.campaigns.push({ id: 0, name: 'All' })
        this.campaigns = this.campaigns.sort(function(a, b){ return a.name.localeCompare(b.name) })
      },

      // Generate report
      async generateReport() {
        this.generatingReport = true
        this.headers = []
        this.chartTableData = null
        this.chartLineData = null
        this.reportLoading = true
        this.chartLineDataSingles = []
        this.subTitle = "Generated @ " + this.$options.filters.prettyDateTime(moment())

        if(this.reportType == "Breakdown Report") {
            await ReportingController.getChartValues({
              campaignId: this.campaignId,
              type: 'table',
              indicator: 'breakdown',
              granularity: 'year',
              screenId: this.selectedScreen === null ? 0 : this.selectedScreen.id,
              campaignBurstId: this.selectedBurst === null ? 0 : this.selectedBurst.id,
            }).then((res) => {
              this.reportLoading = false

              this.chartTableData = res.data.content
              
              for (var i = 0; i < res.data.headers.length; i++)
                this.headers.push({ text: res.data.headers[i], value: res.data.headers[i], sortable: true })
            }).catch((err) => {
              this.reportLoading = false
              this.$root.$emit('snackbarError', ''+ err.response.data.message)
            })
        }

        let chartColors = ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#FF2D00', '#D2FF00', '#003DFF', '#52FF00']
        
        if(this.reportType == "Full Overview") {
          await ReportingController.getChartValues({
            campaignId: this.campaignId,
            type: 'sine',
            indicator: 'plays',
            granularity: 'daily',
            screenId: this.selectedScreen === null ? 0 : this.selectedScreen.id,
            campaignBurstId: this.selectedBurst === null ? 0 : this.selectedBurst.id,
          })
          .then((res) => {
            this.reportLoading = false
            this.chartLineData = res.data

            this.sineChartOptions = {
              series: this.chartLineData.series, 

              chart:{
                height: 500,
                stacked: false,
              },

              title: {
                text: (this.chartLineData.campaignName.length == 1 ? this.chartLineData.campaignName[0] : "Multiple Campaigns"), 
                offsetX: 45, 
                style: {
                  color: '#f3f3f3', 
                  fontSize: '16pt', 
                  fontWeight: 'normal'
                }
              },

              subtitle: {
                text: "Plays per day", 
                offsetX: 45, 
                style: {
                  color: '#f3f3f3', 
                  fontSize: '12pt', 
                  fontWeight: 'normal'
                }
              },

              xaxis: {
                categories: this.chartLineData.categories,
                labels: {
                  style: { 
                    colors: "#f3f3f3"
                  }
                }
              },

              yaxis: {
                labels: {
                  style: { 
                    colors: "#f3f3f3"
                  }
                }
              },

              stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: chartColors,
                width: 2,
                dashArray: 0, 
              },

              grid: {
                borderColor: '#3f3f3f',
              },

              legend: {
                show: true,
                // showForSingleSeries: true,

                labels: {
                  colors: "#f3f3f3",
                },
                
                markers: {
                  fillColors: chartColors,
                },
              },

              dataLabels: {
                enabled: false
              },
            }
          })
          .catch((err) => {
            this.reportLoading = false
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
        }

        this.generatingReport = false
      },

      // Find campaign ID
      async attachPropToCampaign() {
        this.campaigns.forEach((campaign) => {
          if(campaign.id.toString() === this.campaignId.toString())
            this.selectedCampaign = campaign.name
        })
      },
    },
  }
</script>

<style lang="scss">
.explorationContainer .exploreCanvas .canvasFlexBox .displayAreaContainer {
  width: 100%;
  background: #121212 !important;
  position: relative;
}

.apexcharts-tooltip {
  background: #f3f3f3;
  color: #1c1c1c;
}

.apexcharts-canvas
{
  color: #f3f3f3;
}

.loading-bars .v-skeleton-loader__image.v-skeleton-loader__bone { height: 350px; }
.loading-axis .v-skeleton-loader__image.v-skeleton-loader__bone { height: 30px; }
</style>
